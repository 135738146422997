import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { EBackendService } from '@incepto/common-models';
import { UserSessionRepositoryService } from '@incepto/core';
import { IExamPerUseCase } from '../models/exam-per-use-case.model';
import {
  IExamPerUseCaseDetails,
  IExamPerUseCaseOutputs,
} from '../models/exam-per-use-case-details.model';
import { IExamPerUseCaseDetailsView } from '../models/exam-per-use-case-details-view.model';
import { ExamPerUseCaseDetailsDataFormatterService } from './exam-per-use-case-details-data-formatter.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExamPerUseCaseService extends AbstractGatewayClient<IExamPerUseCase> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    http: HttpClient,
    private httpHelperService: HttpHelperService,
    private examPerUseCaseDetailsDataFormatterService: ExamPerUseCaseDetailsDataFormatterService
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      'views/exam-per-usecase'
    );
    this.buildClient();
  }

  private data: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public getExamPerUseCaseDetails(examPerUseCaseId: string) {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.get<IExamPerUseCaseDetails>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/views/exam-per-usecase/${examPerUseCaseId}/detailed`,
      httpOptions
    );
  }

  private getExamPerUseCaseOutputs(examPerUseCaseId: string) {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.get<IExamPerUseCaseOutputs[]>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/views/exam-per-usecase/${examPerUseCaseId}/results-detailed`,
      httpOptions
    );
  }

  async getExamPerUseCaseDetailsForDetailsView(
    examPerUseCaseId: string
  ): Promise<IExamPerUseCaseDetailsView> {
    const examPerUseCasePromise = this.getById(examPerUseCaseId);
    const examPerUseCaseDetailsPromise =
      this.getExamPerUseCaseDetails(examPerUseCaseId).toPromise();
    const examPerUseCaseOutputsPromise =
      this.getExamPerUseCaseOutputs(examPerUseCaseId).toPromise();

    return Promise.all([
      examPerUseCasePromise,
      examPerUseCaseDetailsPromise,
      examPerUseCaseOutputsPromise,
    ]).then(
      ([examPerUsecase, examPerUsecaseDetails, examPerUseCaseOutputs]) => {
        this.data.next(
          JSON.stringify(
            {
              examPerUseCase: examPerUsecase,
              examPerUseCaseDetails: examPerUsecaseDetails,
              examPerUseCaseOutputs: examPerUseCaseOutputs,
            },
            undefined,
            2
          )
        );
        return this.examPerUseCaseDetailsDataFormatterService.formatDataForDisplaying(
          {
            examPerUseCase: examPerUsecase,
            examPerUseCaseDetails: examPerUsecaseDetails,
            examPerUseCaseOutputs: examPerUseCaseOutputs,
          }
        );
      }
    );
  }

  getDataAsObservable(): Observable<string> {
    return this.data.asObservable();
  }
}
