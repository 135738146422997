import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MirthMessageActions from '../actions/mirth-message.actions';
import { IMirthMessage } from '../../../../src/lib/model/mirth-message.model';

export const MIRTH_MESSAGE_FEATURE_KEY = 'mirth-message';

export type RSQLFilter =
  | RSQLFilterRegexQuery
  | RSQLFilterInQuery
  | RSQLFilterGreaterOrEqualQuery
  | RSQLFilterLessOrEqualQuery;

type RSQLFilterRegexQuery = {
  key: string;
  value: string;
  operator: '=rei=';
};

type RSQLFilterInQuery = {
  key: string;
  value: string[];
  operator: '=in=';
};

type RSQLFilterGreaterOrEqualQuery = {
  key: string;
  value: string;
  operator: '=gte=';
};

type RSQLFilterLessOrEqualQuery = {
  key: string;
  value: string;
  operator: '=lte=';
};

export interface MirthMessageState extends EntityState<IMirthMessage> {
  loaded: boolean;
  expandedRowMirthMessage: string | undefined;
  error?: string | null;
  page: number;
  enableNextPage: boolean;
  selectedMirthMessage?: string | number;
  patientIDFilter: RSQLFilterRegexQuery;
  accessionNumberFilter: RSQLFilterRegexQuery;
  createdStartDateFilter: RSQLFilterGreaterOrEqualQuery;
  createdEndDateFilter: RSQLFilterLessOrEqualQuery;
}

export const mirthMessageAdapter: EntityAdapter<IMirthMessage> =
  createEntityAdapter<IMirthMessage>();

export const initialState: MirthMessageState =
  mirthMessageAdapter.getInitialState({
    loaded: false,
    expandedRowMirthMessageId: undefined,
    page: 0,
    enableNextPage: true,
    expandedRowMirthMessage: '',
    error: '',

    patientIDFilter: { key: 'patientID', value: '', operator: '=rei=' },
    accessionNumberFilter: {
      key: 'orderNumbers',
      value: '',
      operator: '=rei=',
    },
    createdStartDateFilter: {
      key: 'createdDate',
      value: '',
      operator: '=gte=',
    },
    createdEndDateFilter: { key: 'createdDate', value: '', operator: '=lte=' },
  });

export const mirthMessageReducer = createReducer(
  initialState,
  on(MirthMessageActions.INIT, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),

  on(
    MirthMessageActions.LOAD_MIRTH_MESSAGE_SUCCESS,
    (state, { mirthMessage }) =>
      mirthMessageAdapter.setAll(mirthMessage, {
        ...state,
        loaded: true,
      })
  ),

  on(MirthMessageActions.SELECT_MIRTH_MESSAGE, (state, { selectedId }) => ({
    ...state,
    selectedId,
  })),
  on(MirthMessageActions.UPDATE_PAGE_NUMBER, (state, { page }) => ({
    ...state,
    page,
  })),
  on(
    MirthMessageActions.UPDATE_ENABLE_NEXT_PAGE,
    (state, { enableNextPage }) => ({
      ...state,
      enableNextPage,
    })
  ),
  on(
    MirthMessageActions.UPDATE_PATIENTID_FILTER,
    (state, { patientIDFilterValue }) => ({
      ...state,
      patientIDFilter: {
        ...state.patientIDFilter,
        value: patientIDFilterValue,
      },
    })
  ),

  on(
    MirthMessageActions.UPDATE_ACCESSION_NUMBER_FILTER,
    (state, { accessionNumberFilterValue }) => ({
      ...state,
      accessionNumberFilter: {
        ...state.accessionNumberFilter,
        value: accessionNumberFilterValue,
      },
    })
  ),
  on(
    MirthMessageActions.UPDATE_CREATED_START_DATE,
    (state, { createdStartDateFilterValue }) => ({
      ...state,
      createdStartDateFilter: {
        ...state.createdStartDateFilter,
        value: createdStartDateFilterValue,
      },
    })
  ),
  on(
    MirthMessageActions.UPDATE_CREATED_END_DATE,
    (state, { createdEndDateFilterValue }) => ({
      ...state,
      createdEndDateFilter: {
        ...state.createdEndDateFilter,
        value: createdEndDateFilterValue,
      },
    })
  )
);
