import { ServerType } from '@incepto-gateway/shared/state';
import { IState } from '@incepto-gateway/shared/status-list';

export interface IExamPerUseCaseDetails {
  id: string;
  createdDate?: string;
  modifiedDate?: string;
  seriesDetailList: ISeriesDetails[];
  priorSeriesDetailList: ISeriesDetails[];
  priorProcessingRequestIdList: string[];
  analysisSeries: { [analysisId: string]: string[] };
  analyzePartialStudy: boolean;
  pseudonymizedData: {
    pseudonymizedPatientID: string;
    pseudonymizedStudyInstanceUID: string;
    pseudonymizedAccessionNumber: string;
    pseudonymizationKey: string;
  };
  numberOfInstancesPerSeries: { [seriesInstanceUID: string]: number };
}

export interface ISeriesDetails {
  state: IState;
  messages: string[];
  patientID: string;
  studyInstanceUID: string;
  seriesInstanceUID: string;
  accessionNumber: string;
  dicomTags: {
    SpecificCharacterSet?: string;
    QueryRetrieveLevel?: string;
    RetrieveAETitle?: string;
    PatientBirthDate?: string;
    InstitutionName?: string;
    StudyDate?: string;
    StudyDescription?: string;
    PatientName?: string;
    SeriesDescription?: string;
    PatientAge?: string;
    StudyTime?: string;
    PatientWeight?: string;
    PhotometricInterpretation?: string;
    PatientSex?: string;
    PerformedProcedureStepDescription?: string;
    PatientSize?: string;
    Modality?: string;
    BodyPartExamined?: string;
    SeriesDate?: string;
    SeriesTime?: string;
  };
  seriesDate: string;
  instanceDetailList: {
    state: IState;
    messages: string[];
    sopInstanceUID: string;
  }[];
}

export interface IExamPerUseCaseOutputs {
  analysisId: string;
  pseudonymizedSeriesInstanceUID?: string;
  resultType?: string;
  numberOfInstances?: number;
  outputAETs?: IOutputAET[];
  modality?: string;
  seriesDescription?: string;
  state: IState;
  errorMessage?: string;
  server: ServerType;
}

export interface IOutputAET {
  outputAET?: {
    orthanc?: string;
    localAet?: string;
    remoteAet?: string;
  };
  state?: IState;
  successDate?: string;
  errorMessage?: string;
}
