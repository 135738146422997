import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { UserSessionRepositoryService } from '@incepto/core';
import { IManualLaunchTask } from '../models/manual-launch-task.model';

import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import { EBackendService } from '@incepto/common-models';

@Injectable({
  providedIn: 'root',
})
export class ManualLaunchTaskService extends AbstractGatewayClient<IManualLaunchTask> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    http: HttpClient,
    private httpHelperService: HttpHelperService
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      'manual-launch'
    );
    this.buildClient();
  }
  postManualLaunch(formData: FormData) {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.post(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/manual-launch/upload`,
      formData,
      { ...httpOptions }
    );
  }
}
