import { IOrigin } from '@incepto-gateway/shared/state';
import { IState, IStep } from '@incepto-gateway/shared/status-list';
import { IPROCESSING_REQUEST_TYPE_LIST } from './processing-request-type-list.model';

export interface IExamPerUseCase {
  id: string;
  processingRequestId: string;
  studyDescription: string;
  useCase: string;
  patientID: string;
  studyInstanceUID: string;
  accessionNumber: string;
  type: IPROCESSING_REQUEST_TYPE_LIST;
  createdDate: string;
  modifiedDate: string | null;
  application: string;
  studyDate: string;
  studyTime: string;
  priorStudyInstanceUIDs: string[];
  workflowInfo: IWorkflowInfo;
  analysesMonitoring: IAnalysisMonitoringInfo[];
  requestRelations?: any[];
  origin: IOrigin;
  rejectedInstances: string[];
  rejectedSeries: string[];
}

interface IWorkflowInfo {
  status: IState;
  step: IStep;
  errorMessage: string;
}

interface IProcessingTimes {
  [step: string]: {
    startDate: string;
    endDate: string;
  };
}

export interface IAnalysisMonitoringInfo {
  analysisId: string;
  open: boolean;
  workflowInfo: IWorkflowInfo;
  triggeredAnalysisId?: string;
  processingRequestId?: string;
  createdDate: string;
  monitoring: {
    [step: string]: {
      [id: string]: {
        pathToResource: string;
        state: string;
        message: string;
        skipCompleteAnalysisVerification: boolean | null;
      };
    };
  };
  processingTimes: IProcessingTimes;
  resultsMonitoring: IResultsMonitoring[];
}

export interface IResultsMonitoring {
  analysisResultId: string;
  monitoring: any;
  processingTimes: IProcessingTimes;
}
