export const STATUS_LIST = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  DONE: 'Used',
  ERROR: 'Error',
  REJECTED: 'Rejected',
  MIXED: 'Not used',
} as const;

export type IState = keyof typeof STATUS_LIST;
