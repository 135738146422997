export const STEP_LIST_VALUE = {
  DICOMS_RECEPTION: 'DICOM Reception',
  CONNECTION_CHECK: 'Connection Check',
  SERIES_STABILITY: 'Series Stability',
  MANDATORY_SERIES: 'Mandatory Series',
  UPLOAD: 'Upload',
  AI_PROCESSING: 'AI Processing',
  RESULTS_TRANSFER: 'Results Transfer',
  COMPLETE: 'Complete',
} as const;

export type IStep = keyof typeof STEP_LIST_VALUE;
