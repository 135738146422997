import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import {
  BackendConfigurationService,
  HttpHelperService,
} from '@incepto/common-clients';
import { EBackendService } from '@incepto/common-models';
import { UserSessionRepositoryService } from '@incepto/core';
import { Observable } from 'rxjs';
import { IApplication } from '../models/application.model';
import { IOrigin } from '../models/origin.model';
import { IUseCaseMap } from '../models/use-case.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends AbstractGatewayClient<IOrigin> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    private httpHelperService: HttpHelperService,
    http: HttpClient
  ) {
    super(backendConfigurationService, userSessionRepositoryService, http, '');
    this.buildClient();
  }

  getAvailableOrigins(): Observable<IOrigin[]> {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.get<IOrigin[]>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/config/origins`,
      httpOptions
    );
  }

  getAvailableUseCases(): Observable<IUseCaseMap> {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.get<IUseCaseMap>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/config/useCases`,
      httpOptions
    );
  }
  getApplications(): Observable<{ [key: string]: IApplication }> {
    const httpOptions = this.httpHelperService.buildHttpOptions();
    return this.http.get<{ [key: string]: IApplication }>(
      `${this.backendConfigurationService.getBackendServiceUrl(
        EBackendService.GATEWAY_SERVICE
      )}/config/applications`,
      httpOptions
    );
  }
}
