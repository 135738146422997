import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  InceptoLogManagerService,
  UserSessionRepositoryService,
} from '@incepto/core';

import {
  AbstractLoginLoginComponent,
  ILoginValidator,
} from '@incepto/login-common';
import { BasicAuthService } from './basic-auth.service';

@Component({
  selector: 'incepto-gateway-basic-login',
  templateUrl: './basic-login.component.html',
  styleUrls: ['./basic-login.component.scss'],
})
export class BasicLoginComponent extends AbstractLoginLoginComponent<string> {
  public form: FormGroup;
  loginError = false;

  constructor(
    private basicAuthService: BasicAuthService,
    private fb: FormBuilder,
    log: InceptoLogManagerService,
    userSessionRepositoryService: UserSessionRepositoryService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(log, userSessionRepositoryService, route, router);

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  init(): void {
    // nothing to do
  }

  buildValidators(): ILoginValidator<string>[] {
    return [
      {
        field: 'username',
        validator: (username: string) => {
          if (username === undefined) {
            return 'Username is not defined';
          }
          return undefined;
        },
      },
      {
        field: 'password',
        validator: (password: string) => {
          if (password === undefined) {
            return 'Password is not defined';
          }
          return undefined;
        },
      },
    ];
  }

  getUserInputs(): Record<string, string> {
    return this.form.value;
  }

  completeLoginStep(userInputs: Record<string, string>): Promise<void> {
    this.loginError = false;
    return this.basicAuthService
      .login(userInputs.username, userInputs.password)
      .catch((error) => {
        if (error.status === 401) {
          this.loginError = true;
        } else {
          this.log.error(error.message);
        }
      })
      .then(() =>
        this.router
          .navigateByUrl(this.returnUrl)
          .catch((error) => {
            this.log.error(error.message, 'Navigation Error', error);
            this.router.navigateByUrl('');
          })
          .then()
      );
  }
}
