import { IResultType } from './result-type-list.model';
import { IOrigin } from './origin.model';

export type MirthMode = 'AUTO' | 'MANUAL' | 'DISABLED';

export type ServerType =
  | 'FORGE'
  | 'ORTHANC'
  | 'CENSUS'
  | 'TEMPLAR_ARCHIVES'
  | 'KEYCLOAK'
  | 'INTERNET_CONNECTION'
  | 'LOCAL_ZIP_ARCHIVE'
  | 'FORWARD'
  | 'POLARIS_PII'
  | 'POLARIS_FORGE'
  | 'POLARIS_UI'
  | 'GATEWAY_CONFIG'
  | 'AI_FILTERS'
  | 'DIRAC';

export type ResultMode = 'DICOM' | 'HL7' | 'POLARIS_DICOM' | 'POLARIS_HL7';

export interface CredentialsDto {
  username: string;
  password: string;
  forceUpdate: boolean;
  params: { [key: string]: string };
}

export interface CompressionDto {
  enabled: boolean;
  recompress: boolean;
  transferSyntaxUID: string;
  ratio: number;
}

export interface IApplication {
  enabled: boolean;
  storeResult: boolean;
  polarisEnabled: boolean;
  doUpload: boolean;
  locale: string;
  collisionGroup: string;
  mirthMode: MirthMode;
  server: ServerType;
  resultTypes: IResultType[];
  triggerOnQueue: string;
  credentials?: CredentialsDto;
  decompressResults: boolean;
  compressExams?: CompressionDto;
  compressResults?: CompressionDto;
  withResults: boolean;
  forwardAet: IOrigin;
  resultModes: ResultMode[];
  analyzePartialStudy: boolean;
  analyzePartialStudyPerSeries: boolean;
  label: string;
}
