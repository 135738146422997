import { createAction, props } from '@ngrx/store';
import { IHomeAction } from '../../models/home-action.model';
import { IOrigin } from '../../models/origin.model';
import { IApplication } from '../../models/application.model';
import { IUseCaseMap } from '../../models/use-case.model';

export const TOGGLE_AUTO_REFRESH = createAction(
  '[Toggle Auto Refresh] Toggle Auto Refresh'
);

export const DISABLE_AUTO_REFRESH = createAction(
  '[Disable Auto Refresh] Disable Auto Refresh'
);

export const loadUseCases = createAction('[UseCases] Load UseCases');

export const loadUseCasesSuccess = createAction(
  '[UseCases] Load UseCases Success',
  props<{ useCases: IUseCaseMap }>()
);

export const loadOrigins = createAction('[Origins] Load Origins');

export const loadOriginsSuccess = createAction(
  '[Origins] Load Origins Success',
  props<{ origins: IOrigin[] }>()
);

export const loadApplications = createAction(
  '[Applications] Load Applications'
);

export const loadApplicationsSuccess = createAction(
  '[Applications] Load Applications Success',
  props<{ applications: { [key: string]: IApplication } }>()
);

export const loadHomeActionConfig = createAction(
  '[HomeActions] Load Home Action Config'
);

export const loadHomeActionConfigSuccess = createAction(
  '[HomeActions] Load Home Action Config Success',
  props<{ homeActions: IHomeAction[] }>()
);
