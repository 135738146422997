import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ExamPerUseCaseDataAccessModule } from '@incepto-gateway/exam-per-use-case-data-access';
import { ManualLaunchTasksDataAccessModule } from '@incepto-gateway/dto/manual-launch/manual-launch-data-access';
import { ComplaintsDataAccessModule } from '@incepto-gateway/dto/complaints/complaints-data-access';
import { SharedUiMaterialModule } from '@incepto-gateway/shared/ui-material';
import { SharedStateModule } from '@incepto-gateway/shared/state';
import { InceptoPopupModule } from '@incepto/popup';
import { InceptoModalModule } from '@incepto/modal';
import { InceptoLoginModule } from '@incepto-gateway/shared/basic-login';
import { HeaderBarModule } from '@incepto-gateway/shared/header-bar';
import { LogoutButtonModule } from '@incepto-gateway/shared/logout-button';
import {
  AppConfigService,
  InceptoCoreModule,
  UserSessionRepositoryService,
} from '@incepto/core';
import { ToastrModule } from 'ngx-toastr';
import { InceptoTranslateModule } from '@incepto/translate';
import { AuthInjectorService } from '@incepto/auth-common';
import { PatientToExludeDataAccessModule } from '@incepto-gateway/dto/patient-to-exclude/patient-to-exclude-data-access';
import { MirthMessageDataAccessModule } from '@incepto-gateway/dto/mirth-message-data-access';

const getDefaultReverseProxyPortFromProtocol = (): string =>
  window.location.protocol === 'https:' ? '443' : '80';

const getDefaultReverseProxyPort = (): string =>
  window.location.port || getDefaultReverseProxyPortFromProtocol(); // window.location.port is "" if 80 on http and 443 on https

const buildReverseProxyPort = (appConfig: AppConfigService): string =>
  appConfig.getAsString('REVERSE_PROXY_PORT') ?? getDefaultReverseProxyPort();

const buildReverseProxyHostname = (appConfig: AppConfigService): string =>
  appConfig.getAsString('REVERSE_PROXY_HOSTNAME') ??
  `${window.location.protocol}//${window.location.hostname}`;

const buildReverseProxyURI = (appConfig: AppConfigService): string =>
  appConfig.getAsString('REVERSE_PROXY_URI') ??
  `${buildReverseProxyHostname(appConfig)}:${buildReverseProxyPort(appConfig)}`;

const buildReverseProxyBackendSubpath = (appConfig: AppConfigService): string =>
  appConfig.getAsString('REVERSE_PROXY_BACKEND_SUBPATH') ?? '/api';

const appConfigInitializerFn =
  (appConfig: AppConfigService, injector: Injector) => async () => {
    await appConfig.loadAppConfig('/assets/runtime-env.json');
    const { env } = appConfig;

    env.GATEWAY_SERVICE_URI = new URL(
      buildReverseProxyBackendSubpath(appConfig),
      buildReverseProxyURI(appConfig)
    ).href;

    env.AUTH_SERVICE_TYPE =
      appConfig.getAsString('AUTH_SERVICE_TYPE') ?? 'BASIC';

    // use injector to prevent angular from initializing before the env loading
    const authService = injector.get(AuthInjectorService).authService;
    await authService.init();
    if (injector.get(UserSessionRepositoryService).shouldAuthFromCache()) {
      await authService.authFromCache();
    }
  };

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    InceptoTranslateModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    SharedStateModule,
    SharedUiMaterialModule,
    InceptoCoreModule,
    NgbModule,
    InceptoLoginModule,
    LogoutButtonModule,
    HeaderBarModule,
    ExamPerUseCaseDataAccessModule,
    ManualLaunchTasksDataAccessModule,
    ComplaintsDataAccessModule,
    MirthMessageDataAccessModule,
    PatientToExludeDataAccessModule,
    InceptoPopupModule,
    InceptoModalModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigInitializerFn,
      multi: true,
      deps: [AppConfigService, Injector],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
