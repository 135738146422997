import { createAction, props } from '@ngrx/store';
import { IMirthMessage } from '../../../../src/lib/model/mirth-message.model';

export const INIT = createAction('[MirthMessage Page] Init');

export const LOAD_MIRTH_MESSAGE_CURRENT_PAGE = createAction(
  '[MirthMessage] Load Mirth Message Current Page'
);

export const LOAD_MIRTH_MESSAGE_NEXT_PAGE = createAction(
  '[MirthMessage Page] Load Mirth Message Next Page'
);
export const UPDATE_ENABLE_NEXT_PAGE = createAction(
  '[MirthMessage EnableNextPage] Update Enable Next Page]',
  props<{ enableNextPage: boolean }>()
);

export const LOAD_MIRTH_MESSAGE_SUCCESS = createAction(
  '[MirthMessage/API] Load Mirth Message Success',
  props<{ mirthMessage: IMirthMessage[] }>()
);
export const SELECT_MIRTH_MESSAGE = createAction(
  '[MirthMessage Page] Select Mirth Message',
  props<{ selectedId: IMirthMessage['id'] }>()
);

export const UPDATE_PATIENTID_FILTER = createAction(
  '[MirthMessage Page] Update Patient ID',
  props<{ patientIDFilterValue: string }>()
);

export const UPDATE_ACCESSION_NUMBER_FILTER = createAction(
  '[MirthMessage Page] Update Accession Number ID',
  props<{ accessionNumberFilterValue: string }>()
);

export const UPDATE_USECASE_EXCLUDED_FILTER = createAction(
  '[MirthMessage Page] Update useCase Excluded',
  props<{ useCaseExcludedToFilterValue: string }>()
);

export const UPDATE_USECASE_INCLUDED_FILTER = createAction(
  '[MirthMessage Page] Update UseCase Included',
  props<{ useCaseIncludedFilterValue: string }>()
);

export const UPDATE_CREATED_START_DATE = createAction(
  '[MirthMessage Page] Created Start Date',
  props<{ createdStartDateFilterValue: string }>()
);

export const UPDATE_CREATED_END_DATE = createAction(
  '[MirthMessage Page] Created End Date',
  props<{ createdEndDateFilterValue: string }>()
);

export const UPDATE_PAGE_NUMBER = createAction(
  '[MirthMessage Page] Update Page Number',
  props<{ page: number }>()
);
