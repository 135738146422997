import { select, Store } from '@ngrx/store';
import {
  MirthMessageState,
  RSQLFilter,
} from '../reducers/mirth-message.reducers';
import { MirthMessageService } from '../../service/mirth-message.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  tap,
  timer,
  toArray,
} from 'rxjs';
import { IQueryParam } from '@incepto/common-models';
import * as MirthMessageSelectors from '../selectors/mirth-message.selectors';
import { Injectable } from '@angular/core';
import * as SharedState from '@incepto-gateway/shared/state';
import { IMirthMessage } from '../../model/mirth-message.model';
import * as mirthMessageActions from '../actions/mirth-message.actions';
import { SharedFacade } from '@incepto-gateway/shared/state';
import { AppConfigService } from '@incepto/core';

@Injectable({
  providedIn: 'root',
})
export class MirthMessageEffects {
  rsqlFilters$: Observable<IQueryParam> = combineLatest([
    this.mirthMessageStore$.pipe(
      select(MirthMessageSelectors.selectPatientIDFilter)
    ),
    this.mirthMessageStore$.pipe(
      select(MirthMessageSelectors.selectAccessionNumberFilter)
    ),
    this.mirthMessageStore$.pipe(
      select(MirthMessageSelectors.selectCreatedStartDateFilter)
    ),

    this.mirthMessageStore$.pipe(
      select(MirthMessageSelectors.selectCreatedEndDateFilter)
    ),
  ]).pipe(map((rsqlFilters) => this.buildSearchRSQLFilter(rsqlFilters)));

  timer$: Observable<number> = this.mirthMessageStore$.pipe(
    select(SharedState.selectAutoRefresh),
    switchMap((autoRefresh) => {
      return !autoRefresh ? of(0) : timer(0, 5000);
    })
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mirthMessageActions.INIT),
      switchMap(() => [
        mirthMessageActions.LOAD_MIRTH_MESSAGE_CURRENT_PAGE(),
        mirthMessageActions.LOAD_MIRTH_MESSAGE_NEXT_PAGE(),
      ])
    )
  );

  getMirthMessagesCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mirthMessageActions.LOAD_MIRTH_MESSAGE_CURRENT_PAGE),

      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('currentPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams]) =>
            this.mirthMessageService.getStream(queryParams).pipe(
              toArray(),
              map((mirthMessage: IMirthMessage[]) => {
                return mirthMessageActions.LOAD_MIRTH_MESSAGE_SUCCESS({
                  mirthMessage,
                });
              }),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  getMirthMessagesNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mirthMessageActions.LOAD_MIRTH_MESSAGE_NEXT_PAGE),
      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('nextPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams, _]) =>
            this.mirthMessageService.getStream(queryParams).pipe(
              toArray(),
              map((mirthMessages) =>
                mirthMessageActions.UPDATE_ENABLE_NEXT_PAGE({
                  enableNextPage: mirthMessages.length > 0,
                })
              ),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  buildPageAndSizeQueryParamsObs(
    pageToConsider: 'currentPage' | 'nextPage'
  ): Observable<IQueryParam[]> {
    return this.mirthMessageStore$.pipe(
      select(MirthMessageSelectors.selectPage),
      map((page) => [
        { key: 'size', value: this.sharedFacade.getMaxElementPerPage() },
        {
          key: 'page',
          value: pageToConsider === 'currentPage' ? page : page + 1,
        },
        { key: 'sort', value: 'createdDate,desc' },
      ])
    );
  }

  buildSearchRSQLFilter(paramsToFilter: RSQLFilter[]): IQueryParam {
    return {
      key: 'search',
      value: paramsToFilter
        .filter((param) => param.value && param.value.length > 0)
        .map((filter) => this.buildSearchFilter(filter))
        .join(';'),
    };
  }

  buildSearchFilter(filter: RSQLFilter) {
    if (
      filter.operator === '=rei=' ||
      filter.operator === '=gte=' ||
      filter.operator === '=lte='
    ) {
      return `${filter.key}${filter.operator}${filter.value}`;
    } else if (filter.operator === '=in=') {
      return `${filter.key}${filter.operator}(${filter.value.join(',')})`;
    }
  }

  buildCompleteQueryParamsObservable(
    pageObservable: Observable<IQueryParam[]>,
    rsqlSearchFilterObservable: Observable<IQueryParam>
  ): Observable<IQueryParam[]> {
    return combineLatest([
      pageObservable,
      rsqlSearchFilterObservable.pipe(
        tap((_) =>
          this.mirthMessageStore$.dispatch(
            mirthMessageActions.UPDATE_PAGE_NUMBER({ page: 0 })
          )
        )
      ),
    ]).pipe(
      map(([page, rsqlFilter]) =>
        [...page, rsqlFilter].filter((queryParam) => queryParam.value !== '')
      )
    );
  }

  constructor(
    private mirthMessageStore$: Store<MirthMessageState>,
    private mirthMessageService: MirthMessageService,
    private actions$: Actions,
    private toastr: ToastrService,
    private sharedFacade: SharedFacade
  ) {}
}
