import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MirthMessageEffects } from './state/effects/mirth-message.effects';
import * as fromMirthMessage from './state/reducers/mirth-message.reducers';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromMirthMessage.MIRTH_MESSAGE_FEATURE_KEY,
      fromMirthMessage.mirthMessageReducer
    ),
    EffectsModule.forFeature([MirthMessageEffects]),
  ],
})
export class MirthMessageDataAccessModule {}
