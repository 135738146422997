import { Component } from '@angular/core';
import { SharedFacade } from '@incepto-gateway/shared/state';
import { AppConfigService } from '@incepto/core';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'incepto-gateway-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent {
  siteName = '';

  constructor(
    private appConfigService: AppConfigService,
    private sharedFacade: SharedFacade
  ) {
    this.siteName = this.appConfigService.getAsString('DD_HOSTNAME') ?? ' ';
  }

  isComplaintEnabled(): Observable<boolean> {
    return this.sharedFacade.enabledApplications$.pipe(
      map(
        (applications) =>
          applications.find((app) => app.key === 'COMPLAINT')?.enabled ?? false
      )
    );
  }
}
