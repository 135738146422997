import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MIRTH_MESSAGE_FEATURE_KEY,
  mirthMessageAdapter,
  MirthMessageState,
} from '../reducers/mirth-message.reducers';

export const getMirthMessageState = createFeatureSelector<MirthMessageState>(
  MIRTH_MESSAGE_FEATURE_KEY
);

const { selectAll, selectEntities } = mirthMessageAdapter.getSelectors();

export const getMirthMessageLoaded = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.loaded
);

export const getAllMirthMessage = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => selectAll(state)
);

export const selectPage = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.page
);

export const selectEnableNextPage = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.enableNextPage
);

export const getMirthMessageEntities = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => selectEntities(state)
);

export const selectPatientIDFilter = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.patientIDFilter
);

export const selectPatientIDFilterValue = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.patientIDFilter.value
);

export const selectAccessionNumberFilter = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.accessionNumberFilter
);

export const selectAccessionNumberFilterValue = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.accessionNumberFilter.value
);

export const selectCreatedStartDateFilter = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.createdStartDateFilter
);
export const selectCreatedStartDateFilterValue = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.createdStartDateFilter.value
);

export const selectCreatedEndDateFilter = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.createdEndDateFilter
);
export const selectCreatedEndDateFilterValue = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state.createdEndDateFilter.value
);

export const getSelectedMirthMessageId = createSelector(
  getMirthMessageState,
  (state: MirthMessageState) => state
);

export const getSelected = createSelector(
  getMirthMessageState,
  getSelectedMirthMessageId,
  (entities, getSelectedMirthMessage) => getSelectedMirthMessage
);
