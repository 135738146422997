import { Injectable } from '@angular/core';
import * as SharedState from '@incepto-gateway/shared/state';
import { IQueryParam } from '@incepto/common-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { IManualLaunchTask } from '../../models/manual-launch-task.model';
import { ManualLaunchTaskService } from '../../service/manual-launch-task.service';
import { manualLaunchTaskActions } from '../actions/manual-launch-task.actions.types';
import {
  ManualLaunchTaskState,
  RSQLFilter,
} from '../reducers/manual-launch.reducers';
import * as ManualLaunchSelectors from '../selectors/manual-launch.selectors';
import { SharedFacade } from '@incepto-gateway/shared/state';

@Injectable({
  providedIn: 'root',
})
export class ManualLaunchTasksEffects {
  rsqlFilters$: Observable<IQueryParam> = combineLatest([
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectPatientIDFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectAccessionNumberFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectProcessingRequestIdFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectUseCaseFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectStatusFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectOriginFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectTypeFilter)
    ),
    this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectCsvFilter)
    ),
  ]).pipe(
    map((rsqlFilters) =>
      this.buildSearchRSQLFilter([
        ...rsqlFilters,
        { key: 'manualLaunchTaskType', value: 'CSV', operator: '==' },
      ])
    )
  );

  timer$: Observable<number> = this.manualLaunchTaskStore$.pipe(
    select(SharedState.selectAutoRefresh),
    switchMap((autoRefresh) => {
      return !autoRefresh ? of(0) : timer(0, 5000);
    })
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manualLaunchTaskActions.INIT),
      switchMap(() => [
        manualLaunchTaskActions.LOAD_MANUAL_LAUNCH_TASKS_CURRENT_PAGE(),
        manualLaunchTaskActions.LOAD_MANUAL_LAUNCH_TASKS_NEXT_PAGE(),
      ])
    )
  );

  getManualLaunchTasksCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manualLaunchTaskActions.LOAD_MANUAL_LAUNCH_TASKS_CURRENT_PAGE),
      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('currentPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams, _]) =>
            this.manualLaunchTaskService.getStream(queryParams).pipe(
              toArray(),
              map((manualLaunchTasks: IManualLaunchTask[]) => {
                return manualLaunchTaskActions.MANUAL_LAUNCH_TASKS_LOADED_SUCCESSFULLY(
                  {
                    manualLaunchTasks,
                  }
                );
              }),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  getManualLaunchTasksNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manualLaunchTaskActions.LOAD_MANUAL_LAUNCH_TASKS_NEXT_PAGE),
      switchMap(() => {
        return combineLatest([
          this.buildCompleteQueryParamsObservable(
            this.buildPageAndSizeQueryParamsObs('nextPage'),
            this.rsqlFilters$
          ),
          this.timer$,
        ]).pipe(
          switchMap(([queryParams, _]) =>
            this.manualLaunchTaskService.getStream(queryParams).pipe(
              toArray(),
              map((manualLaunchTasks) =>
                manualLaunchTaskActions.UPDATE_ENABLE_NEXT_PAGE({
                  enableNextPage: manualLaunchTasks.length > 0,
                })
              ),
              catchError((error: string) => {
                this.toastr.error(error);
                return of(SharedState.DISABLE_AUTO_REFRESH());
              })
            )
          )
        );
      })
    )
  );

  uploadCsvAndConf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(manualLaunchTaskActions.POST_CSV_CONFIGURATION),
      map((action) => {
        return this.buildFormData(action.csv, action.csvConfig);
      }),
      concatMap((formData: FormData) => {
        return this.manualLaunchTaskService.postManualLaunch(formData).pipe(
          map(() => {
            this.toastr.success('Upload Success');
            return manualLaunchTaskActions.CSV_CONFIGURATION_FILES_POSTED_SUCCESSFULLY(
              {
                payload: 'Upload Success',
              }
            );
          }),
          catchError((error) => {
            this.toastr.error(error.error.message, error.status);
            return of(
              manualLaunchTaskActions.CSV_CONFIGURATION_FILES_POST_FAILURE({
                error,
              })
            );
          })
        );
      })
    )
  );

  buildPageAndSizeQueryParamsObs(
    pageToConsider: 'currentPage' | 'nextPage'
  ): Observable<IQueryParam[]> {
    return this.manualLaunchTaskStore$.pipe(
      select(ManualLaunchSelectors.selectPage),
      map((page) => [
        { key: 'size', value: this.sharedFacade.getMaxElementPerPage() },
        {
          key: 'page',
          value: pageToConsider === 'currentPage' ? page : page + 1,
        },
        { key: 'sort', value: 'createdDate,desc' },
      ])
    );
  }
  buildFormData(csv: File, config: Blob): FormData {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('csvConfig', config);
    return formData;
  }

  buildSearchRSQLFilter(paramsToFilter: RSQLFilter[]): IQueryParam {
    return {
      key: 'search',
      value: paramsToFilter
        .filter((param) => param.value && param.value.length > 0)
        .map((filter) => this.buildSearchFilter(filter))
        .join(';'),
    };
  }

  buildSearchFilter(filter: RSQLFilter) {
    if (filter.operator === '=rei=') {
      return `${filter.key}=rei=${filter.value}`;
    } else if (filter.operator === '=in=') {
      return `${filter.key}=in=${filter.value.join(',')}`;
    } else {
      return `${filter.key}==${filter.value}`;
    }
  }

  buildCompleteQueryParamsObservable(
    pageObservable: Observable<IQueryParam[]>,
    rsqlSearchFilterObservable: Observable<IQueryParam>
  ): Observable<IQueryParam[]> {
    return combineLatest([
      pageObservable,
      rsqlSearchFilterObservable.pipe(
        tap((_) =>
          this.manualLaunchTaskStore$.dispatch(
            manualLaunchTaskActions.UPDATE_PAGE_NUMBER({ page: 0 })
          )
        )
      ),
    ]).pipe(
      map(([page, rsqlFilter]) =>
        [...page, rsqlFilter].filter((queryParam) => queryParam.value !== '')
      )
    );
  }

  constructor(
    private manualLaunchTaskStore$: Store<ManualLaunchTaskState>,
    private manualLaunchTaskService: ManualLaunchTaskService,
    private actions$: Actions,
    private toastr: ToastrService,
    private sharedFacade: SharedFacade
  ) {}
}
