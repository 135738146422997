import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as SharedReducers from '../reducers/shared.reducers';

export const selectSharedState =
  createFeatureSelector<SharedReducers.SharedState>(
    SharedReducers.SHARED_STATE
  );

export const selectAutoRefresh = createSelector(
  selectSharedState,
  (autoRefresh: SharedReducers.SharedState) => autoRefresh.autoRefresh
);

export const getUseCases = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) => Object.keys(state.useCases)
);

export const getApplications = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) => Object.keys(state.applications)
);

export const getUseCasesForComplaint = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) =>
    Object.entries(state.useCases)
      .filter(
        ([, workflowMode]) =>
          workflowMode !== 'COMPLAINT' &&
          workflowMode !== 'DATA_COLLECTION' &&
          workflowMode !== 'PSEUDONYMIZATION_ONLY'
      )
      .map(([useCase]) => useCase)
);

export const getOrigins = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) => state.origins
);

export const selectApplications = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) => state.applications
);

export const selectEnabledApplications = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) => {
    return Object.entries(state.applications)
      .filter(([, value]) => value.enabled)
      .map(([key, value]) => ({ key, ...value }));
  }
);

export const getHomeActionConfig = createSelector(
  selectSharedState,
  (state: SharedReducers.SharedState) => state.homeActions
);
