<div class="header flex-row">
  <div class="header__left">
    <a routerLink="/board" class="header__logo">
      <img src="assets/images/icons/incepto-logo.png" alt="INCEPTO logo" />
    </a>
    <span class="header__gateway">Gateway</span>
  </div>

  <div class="header__menu flex-row">
    <ul class="header__menu-main">
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a routerLink="/board">
          <!-- TO DO: ADD SVG icon -->
          <span> Monitoring</span>
        </a>
      </li>
      <li *ngIf="isComplaintEnabled() | async" routerLinkActive="active">
        <a routerLink="/complaint" routerLinkActive="active">
          <span>Complaint</span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/pseudonymization" routerLinkActive="active">
          <span>Pseudonymization</span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/manual-launch" routerLinkActive="active">
          <span>Manual launch</span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/patient-to-exclude" routerLinkActive="active">
          <span>Patient to exclude</span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/mirth-message" routerLinkActive="active">
          <span>Mirth message</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="header__right">
    <incepto-gateway-resource-monitors></incepto-gateway-resource-monitors>
    <div class="header__resource-monitors-site-name">{{ siteName }}</div>
    <incepto-gateway-logout-button></incepto-gateway-logout-button>
  </div>
</div>
