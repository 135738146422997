import { createReducer, on } from '@ngrx/store';
import * as SharedActions from '../actions/shared.actions';
import { IHomeAction } from '../../models/home-action.model';
import { IOrigin } from '../../models/origin.model';
import { IApplication } from '../../models/application.model';
import { IUseCaseMap } from '../../models/use-case.model';

export const SHARED_STATE = 'shared-state';

export interface SharedState {
  autoRefresh: boolean;
  useCases: IUseCaseMap;
  origins: IOrigin[];
  applications: { [key: string]: IApplication };
  homeActions: IHomeAction[];
}

export const initialState: SharedState = {
  autoRefresh: true,
  useCases: {},
  origins: [],
  applications: {},
  homeActions: [],
};

export const sharedReducer = createReducer(
  initialState,
  on(SharedActions.TOGGLE_AUTO_REFRESH, (state) => {
    return {
      ...state,
      autoRefresh: !state.autoRefresh,
    };
  }),

  on(SharedActions.DISABLE_AUTO_REFRESH, (state) => {
    return {
      ...state,
      autoRefresh: false,
    };
  }),

  on(SharedActions.loadUseCasesSuccess, (state, { useCases }) => ({
    ...state,
    useCases: useCases,
    loaded: true,
  })),

  on(SharedActions.loadOriginsSuccess, (state, { origins }) => ({
    ...state,
    origins: origins,
  })),

  on(SharedActions.loadApplicationsSuccess, (state, { applications }) => ({
    ...state,
    applications: applications,
  })),

  on(SharedActions.loadHomeActionConfigSuccess, (state, { homeActions }) => ({
    ...state,
    homeActions: homeActions,
  }))
);
