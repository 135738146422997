import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendConfigurationService } from '@incepto/common-clients';
import { UserSessionRepositoryService } from '@incepto/core';
import { AbstractGatewayClient } from '@incepto-gateway/shared/common-gateway-client';
import { IMirthMessage } from '../model/mirth-message.model';

@Injectable({
  providedIn: 'root',
})
export class MirthMessageService extends AbstractGatewayClient<IMirthMessage> {
  constructor(
    backendConfigurationService: BackendConfigurationService,
    userSessionRepositoryService: UserSessionRepositoryService,
    http: HttpClient
  ) {
    super(
      backendConfigurationService,
      userSessionRepositoryService,
      http,
      'mirth'
    );
    this.buildClient();
  }
}
