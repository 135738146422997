export interface IResourceMonitorElement {
  identifier: string;
  configEnabled: boolean;
  monitoringEnabled?: boolean;
  status?: boolean;
}

export interface DetailedResourceMonitorElement
  extends IResourceMonitorElement {
  className: string;
}

export type CategoryType = 'APPLICATION_SERVER' | 'SYSTEM' | 'MODALITY';

export type IResourceMonitors = {
  [key in CategoryType]: IResourceMonitorElement[];
};

export const categoryDisplayNames: { [key in any]: string } = {
  APPLICATION_SERVER: 'Application Servers',
  SYSTEM: 'System',
  MODALITY: 'Modality',
  APPLICATIONS: 'Applications',
};
