import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as MirthMessageSelectors from '../selectors/mirth-message.selectors';
import * as MirthMessageActions from '../actions/mirth-message.actions';

@Injectable({ providedIn: 'root' })
export class MirthMessageFacade {
  constructor(private store: Store) {}
  page$ = this.store.pipe(select(MirthMessageSelectors.selectPage));

  enableNextPage$ = this.store.pipe(
    select(MirthMessageSelectors.selectEnableNextPage)
  );

  mirthMessage$ = this.store.pipe(
    select(MirthMessageSelectors.getAllMirthMessage)
  );

  selectedMirthMessage$ = this.store.pipe(
    select(MirthMessageSelectors.getSelected)
  );

  loaded$ = this.store.pipe(
    select(MirthMessageSelectors.getMirthMessageLoaded)
  );

  patientIDFilterValue$ = this.store.pipe(
    select(MirthMessageSelectors.selectPatientIDFilterValue)
  );

  accessionNumberFilterValue$ = this.store.pipe(
    select(MirthMessageSelectors.selectAccessionNumberFilterValue)
  );

  createdDateStartFilterValue$ = this.store.pipe(
    select(MirthMessageSelectors.selectCreatedStartDateFilterValue)
  );

  createdDateEndFilterValue$ = this.store.pipe(
    select(MirthMessageSelectors.selectCreatedEndDateFilterValue)
  );

  allMirthMessage$ = this.store.pipe(
    select(MirthMessageSelectors.getAllMirthMessage)
  );

  init() {
    this.store.dispatch(MirthMessageActions.INIT());
  }

  select(selectedId: string) {
    this.store.dispatch(
      MirthMessageActions.SELECT_MIRTH_MESSAGE({ selectedId })
    );
  }

  setPage(page: number) {
    this.store.dispatch(MirthMessageActions.UPDATE_PAGE_NUMBER({ page }));
  }

  setPatientIdFilter(patientIDFilterValue: string) {
    this.store.dispatch(
      MirthMessageActions.UPDATE_PATIENTID_FILTER({ patientIDFilterValue })
    );
  }

  setAccessionNumberFilter(accessionNumberFilterValue: string) {
    this.store.dispatch(
      MirthMessageActions.UPDATE_ACCESSION_NUMBER_FILTER({
        accessionNumberFilterValue,
      })
    );
  }

  setUseCaseIncludedFilter(useCaseIncludedFilterValue: string) {
    this.store.dispatch(
      MirthMessageActions.UPDATE_USECASE_INCLUDED_FILTER({
        useCaseIncludedFilterValue,
      })
    );
  }

  setUseCaseExcludedFilter(useCaseExcludedToFilterValue: string) {
    this.store.dispatch(
      MirthMessageActions.UPDATE_USECASE_EXCLUDED_FILTER({
        useCaseExcludedToFilterValue,
      })
    );
  }

  setCreatedDateStartFilterValue(createdStartDateFilterValue: string) {
    this.store.dispatch(
      MirthMessageActions.UPDATE_CREATED_START_DATE({
        createdStartDateFilterValue,
      })
    );
  }
  setCreatedDateEndFilterValue(createdEndDateFilterValue: string) {
    this.store.dispatch(
      MirthMessageActions.UPDATE_CREATED_END_DATE({
        createdEndDateFilterValue,
      })
    );
  }
}
