import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: '/board', pathMatch: 'full' },

  {
    path: 'board',
    loadChildren: () =>
      import('@incepto-gateway/routes/exam-per-use-case-route').then(
        (module) => module.ExamPerUseCaseRouteModule
      ),
  },

  {
    path: 'pseudonymization',
    loadChildren: () =>
      import('@incepto-gateway/routes/pseudonymization').then(
        (module) => module.PseudonymizationModule
      ),
  },

  {
    path: 'complaint',
    loadChildren: () =>
      import('@incepto-gateway/routes/complaint').then(
        (module) => module.ComplaintsModule
      ),
  },

  {
    path: 'manual-launch',
    loadChildren: () =>
      import('@incepto-gateway/routes/manual-launch').then(
        (module) => module.ManualLaunchModule
      ),
  },
  {
    path: 'patient-to-exclude',
    loadChildren: () =>
      import('@incepto-gateway/routes/patient-to-exclude').then(
        (module) => module.PatientToExcludeModule
      ),
  },
  {
    path: 'mirth-message',
    loadChildren: () =>
      import('@incepto-gateway/routes/mirth-message').then(
        (module) => module.MirthMessageModule
      ),
  },

  //TO DO  ADD PageNotFoundModule
  //{ path: '**', loadChildren:() => import('@incepto-gateway/routes/page-not-found').then((module)=>module.PageNotFoundModule)},
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule, CommonModule],
})
export class AppRoutingModule {}
